import React from 'react'



const Projects = () => {
  return (
    <>
      <div className='pt-[222px] h-screen w-full text-4xl font-bold text-center'>We will update soon !!</div>
    </>
  )
}

export default Projects


