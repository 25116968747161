import image1 from "./Picture1.png";
import image2 from "./Picture2.png";
import image3 from "./Picture3.png";
import image4 from "./Picture4.png";
import image5 from "./Picture5.png";
import image6 from "./Picture6.png";
import image7 from "./Picture7.png";
import image8 from "./Picture8.png";
import image9 from "./Picture9.png";
import image10 from "./Picture10.png";
import image11 from "./Picture11.png";
import image12 from "./Picture12.png";
import image13 from "./Picture13.png";
import image14 from "./Picture14.png";
import image15 from "./Picture15.png";
import image16 from "./Picture16.png";
import image17 from "./Picture17.png";
import image18 from "./Picture18.png";
const AvImagesArray = [

  {
    url: image1,
  },

  {
    url: image2,
  },

  {
    url: image3,
  },

  {
    url: image4,
  },

  {
    url: image5,
  },

  {
    url: image6,
  },

  {
    url: image7,
  },

  {
    url: image8,
  },
  {
    url: image18,
  },

  {
    url: image9,
  },

  {
    url: image10,
  },

  {
    url: image11,
  },

  {
    url: image12,
  },

  {
    url: image13,
  },
  {
    url: image14,
  },
  {
    url: image15,
  },
  {
    url: image16,
  },
  {
    url: image17,
  },
];
export default AvImagesArray;